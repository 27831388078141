/**
* Creating slider
*
* @author Wesley Cheung <wesley@click.nl>
*/
var slider = function(){
    var self = this;
    var $slider = $('.js-slider'),
    $js_prev = $('.js-slider-prev'),
    $js_next = $('.js-slider-next');

    /**
    * Build options by checking on data-slider-type attribute.
    * Default means slider without any options.
    *
    * Write down the case and send options.
    * @param   {string} slider_type      the name of the block
    * @return  {array}  options          the options for slick carousel
    * @author  Wesley Cheung <wesley@click.nl>
    */
    self.buildOptions = function(slider_type, autoplay) {
        var options = {};

        switch(slider_type){
            // Blocks type
            case 'blocks':
                options = {
                    prevArrow: $('.slick-prev'),
                    nextArrow: $('.slick-next'),
                    slidesToScroll: 1,
                    mobileFirst: true,
                    infinite: true,
                    autoplay: true,
                    speed: 950,
                    // the magic
                    responsive: [
                        {
                            breakpoint: 1200,
                            settings: {
                                slidesToShow: 5
                            }
                        },
                        {
                            breakpoint: 960,
                            settings: {
                                slidesToShow: 4
                            }
                        },
                        {
                            breakpoint: 720,
                            settings: {
                                slidesToShow: 3
                            }
                        },
                        {
                            breakpoint: 520,
                            settings: {
                                slidesToShow: 2
                            }
                        },
                        {
                            breakpoint: 300,
                            settings: {
                                slidesToShow: 1
                            }
                        }
                    ]
                };
            break;

            // News type
            case 'news':
                options = {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    prevArrow: $('.slick-prev'),
                    nextArrow: $('.slick-next'),
                    mobileFirst: true,
                    infinite: false,
                    // the magic
                    responsive: [
                        {
                            breakpoint: 960,
                            settings: {
                                slidesToShow: 3,
                                slidesToScroll: 3,
                            }
                        },
                        {
                            breakpoint: 520,
                            settings: {
                                slidesToShow: 2,
                                slidesToScroll: 2,
                            }
                        },
                        {
                            breakpoint: 300,
                            settings: {
                                slidesToShow: 1,
                                slidesToScroll: 1,
                            }
                        }
                    ]
                };
            break;

            // About type
            case 'about':
                options = {
                    autoplay: true,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    pauseOnHover: true,
                    speed: 900,
                    arrows: false,
                };
            break;
            
            default:
            break;
        }

        if(autoplay) {
            options.autoplay = true;
        }

        return options;
    }

    /**
    * Initialize the slider
    *
    * @author Wesley Cheung <wesley@click.nl>
    */
    self.init = function(){
        $slider.each(function(){
            var slider_type = $(this).attr('data-slider-type');
            var slider_autoplay = $(this).attr('data-slider-autoplay');
            $(this).slick(self.buildOptions(slider_type, slider_autoplay));
        });

        $js_prev.on('click', function(){
            $slider.slick('slickPrev');
        });

        $js_next.on('click', function(){
            $slider.slick('slickNext');
        });
    }
}
