/**
 * Load more and less transactions at the orders page
 */
window.transaction = function()
{
    var self                    = this;
    var $load_more_btn          = $('.js-load-transactions');
    var transactions            = $('.js-hidden-transaction');

    /**
     * @author Hasan Özaynaci <hasan@click.nl>
     * Initialize click listener and start
     */

    self.init = function()
    {
        $load_more_btn.on('click', self.loadTransactions);
    };

    /**
     * @author Hasan Özaynaci <hasan@click.nl>
     * @params event
     * Load more transactions
     */

    self.loadTransactions = function(e)
    {
        e.preventDefault();

       transactions.removeClass('hide');
        // Checking if hide class exists in a table cell
        if(!transactions.hasClass('hide')) {
            // Change the text and add a new class to the button
            $load_more_btn.text( Lang.get('frontend.js.hero.show_less') );
            $load_more_btn.toggleClass('js-load-less-transactions');
            // Set a new click listener to hide transactions bigger than 10
            $('.js-load-less-transactions').on('click', self.loadLessTransactions);
        }
    };
    
    /**
     * @author Hasan Özaynaci <hasan@click.nl>
     * @params event
     * Load less transactions
     */

    self.loadLessTransactions = function(e) {
        e.preventDefault();
        transactions.addClass('hide');
        $load_more_btn.text( Lang.get('frontend.js.hero.show_all') );

        if($load_more_btn.hasClass('js-load-less-transactions')) {
            $load_more_btn.toggleClass('js-load-less-transactions');
        }
        // Set a new click listener after everything is finished, so there will be a never ending cycle
        $load_more_btn.on('click', self.loadTransactions);
    }
}