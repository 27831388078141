/**
 * Activate Equalizer
 *
 * @author Wesley Cheung <wesley@click.nl>
 */
var equalizer = function(){
    var self = this;
    var $target = $('.js-equalizer');

    /**
     * Initialize Equalizer
     *
     * @author Wesley Cheung <wesley@click.nl>
     */
    self.init = function(){
        $target.each(function(){
            new Foundation.Equalizer($(this));
        });
    }

    /**
     * Apply the height
     *
     * @author Wesley Cheung <wesley@click.nl>
     */
    self.applyHeight = function(){
        $target.each(function(){
            var elem = new Foundation.Equalizer($(this));
            elem.applyHeight();
        });
    }
}

/**
 * Activate Interchange
 *
 * @author Wesley Cheung <wesley@click.nl>
 */
var interchange = function(){
    var self = this;
    var $target = $('.js-interchange');

    /**
     * Initialize Equalizer
     *
     * @author Wesley Cheung <wesley@click.nl>
     */
    self.init = function(){
        $target.each(function(){
            new Foundation.Interchange($(this));
        });
    }
}

/**
 * Activate Reveal
 *
 * @author Wesley Cheung <wesley@click.nl>
 */
var reveal = function(){
    var self = this;
    var $target = $('.js-reveal');

    /**
     * Initialize Equalizer
     *
     * @author Wesley Cheung <wesley@click.nl>
     */
    self.init = function(){
        new Foundation.Reveal($(this));
    }
}
