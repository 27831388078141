/*
 * CUSTOM JAVASCRIPT
 *
 * All of your custom javascript can be placed below.
 * Do NOT forgot to add comments above the different functions or functionalities that need explaining.
 *
 */

/**
 * Nice Select2 dropdown with search ability
 *
 * @author Rianne Oosthoek
 * @credits https://select2.github.io/
 */
function checkInitSelect2(){
    $('.js-select2-basic').each(function(){
        var $this = $(this);
        // Check if this select hasn't been initialized yet
        if( $this.attr('data-select2-initialized') === 'false' ){
            // Init Select2
            $this.select2({
                language: 'nl'
            });
            // Set attribute value to true so it won't get initialized again
            $this.attr('data-select2-initialized', 'true');
        }
    });
};

/**
 * Lightcase
 *
 * @author Wesley Cheung <wesley@click.nl>
 */
var lightcase = function(){
    var self = this;

    self.init = function(){
        $('.js-lightcase[data-rel^=lightcase]').lightcase();
    };
};

/**
 * Share popup
 *
 * @author Wesley Cheung <wesley@click.nl>
 * @credits http://stackoverflow.com/questions/6754260/popup-open-position-in-chrome/17972796#17972796
 */
var share_pop_up = function(){
    var self = this;
    var $target = $('.js-share');

    self.init = function(){
        // When clicked on $target
        $target.on('click', function (e){
            e.preventDefault();

            // the data for making the popup
            var left_position, top_position,
                width = 400,
                height = 300,
                url = $(this).attr('href');

            left_position = (window.screen.width / 2) - (width / 2);
            top_position = (window.screen.height / 2) - (height / 2);

            // store the popup in variable win
            var win = window.open(url, '_blank', 'toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=' + width + ', height=' + height);

            // open the popup and move it to the coordinates
            win.moveTo(left_position, top_position);
        });
    };
};

/**
 * Sticky Menu
 *
 * @author Wesley Cheung <wesley@click.nl>
 */
var sticky_menu = function(){
    var self = this,
        $top_bar = $('.js-top-bar'),
        $sticky_menu = $('.js-sticky-menu'),
        $sticky_logo = $('.js-sticky-logo'),
        $adjust_top = $('.js-adjust-top');

    /**
     * Initialize Sticky Menu
     * @author Wesley Cheung <wesley@click.nl>
     */
    self.init = function(){
        // If the MediaQuery is atleast large according to Foundation
        if (Foundation.MediaQuery.atLeast('large')){
            self.stickyDesktop();
        } else {
            self.stickyMobile('init');
        }

        // When Foundation MediaQuery is changing.
        $(window).on('changed.zf.mediaquery', function (event, new_size, old_size){
            if (new_size == 'medium' || new_size == 'small'){
                self.stickyMobile('init');
            } else {
                // When the new_size is not medium or small. reset sticky mobile and show desktop
                self.stickyMobile('destroy');
                self.stickyDesktop();
            }
        });
    };

    /**
     * Sticky menu function
     * @param  {string} state tell the switch if you want to init or destroy it by removing classes
     * @author Wesley Cheung <wesley@click.nl>
     */
    self.stickyMobile = function (state){
        switch (state){
            // Add classes
            case 'init':
                $top_bar.addClass('menu-fixed');
                $adjust_top.addClass('no-padding-top');
                $adjust_top.css('margin-top', Foundation.Box.GetDimensions($top_bar).height);
                break;

            // Reset the classes
            case 'destroy':
                $top_bar.removeClass('menu-fixed');
                $adjust_top.removeClass('no-padding-top');
                $adjust_top.css('margin-top', '0');
                break;

            default:
                break;
        }
    };

    /**
     * Sticky desktop function
     * @author Wesley Cheung <wesley@click.nl>
     */
    self.stickyDesktop = function(){
        // While scrolling, check if it touches the top bar.
        $(window).scroll(function (e){
            if ($(window).scrollTop() >= Foundation.Box.GetDimensions($top_bar).height){
                $sticky_menu.css('position', 'fixed');
                $sticky_menu.css('top', '0');
            } else {
                $sticky_menu.css('position', 'absolute');
                $sticky_menu.css('top', 'inherit');
            }

            if ($(window).scrollTop() >= Foundation.Box.GetDimensions($top_bar).height * 2){
                $sticky_logo.addClass('is-sticky-logo');

            }else{
                $sticky_logo.removeClass('is-sticky-logo');
            }
        });
    };
};

/**
 * Category responsive
 * Changes hover state to active for mobile/tablet
 *
 * @author Wesley Cheung <wesley@click.nl>
 */
var category_responsive = function(){
    var self = this;

    self.init = function(){
        var $js_category = $('.js-category');

        if (Foundation.MediaQuery.atLeast('xlarge')){
            // Do nothing when MediaQuery is at least large
        } else {
            $js_category.addClass('is-active');
        }

        // When Foundation MediaQuery is changing.
        $(window).on('changed.zf.mediaquery', function (event, new_size, old_size){
            if (new_size == 'large' || new_size == 'medium' || new_size == 'small'){
                $js_category.addClass('is-active');
            } else {
                // When the new_size is not medium or small. reset sticky mobile and show desktop
                $js_category.removeClass('is-active');
            }
        });
    };
};

/**
 * Menu anchor scroll to
 *
 * @author Wesley Cheung <wesley@click.nl>
 */
var menu_scroll_to = function(){
    var self = this,
        $body = $('html, body'),
        $anchor = $('.js-menu-anchor'),
        $sticky_menu;

    self.init = function(){
        // When one of the anchor is clicked
        $anchor.on('click', function (e){
            var scroll_to = $(this).attr('href');

            // Give the correct object according to the media query.
            if (Foundation.MediaQuery.atLeast('large')){
                $sticky_menu = $('.js-sticky-menu');
            } else {
                $sticky_menu = $('.js-top-bar');
            }
            // Animate to the destination
            $body.stop().animate(
                {scrollTop: Foundation.Box.GetDimensions($(scroll_to)).offset.top - Foundation.Box.GetDimensions($sticky_menu).height},
                '500', function(){
                }
            );
            e.preventDefault();
        });
    };
};

/**
 * Does the same as menu scroll to but this can be activated directly.
 * @author Wesley Cheung <wesley@click.nl>
 */
var scroll_to = function( scroll_to ){
    var $body = $('html, body'),
        $sticky_menu;

    $(window).on('load', function(){
        if (Foundation.MediaQuery.atLeast('large')){
            $sticky_menu = $('.js-sticky-menu');
        } else {
            $sticky_menu = $('.js-top-bar');
        }

        // Let's wait half a sec for slower ajax loading (hack)
        var t = setTimeout(function(){
            $body.stop().animate(
                {scrollTop: Foundation.Box.GetDimensions($(scroll_to)).offset.top - Foundation.Box.GetDimensions($sticky_menu).height},
                '500', function(){
                }
            );
        }, 500);
    });
};

/**
 * Mobile Menu
 *
 * @author Wesley Cheung <wesley@click.nl>
 */
var mobile_menu = function(){
    var self = this,
        $menu_button = $('.js-menu-button'),
        $menu_anchor = $('.js-mobile-menu-anchor'),
        $menu = $('.js-menu'),
        is_active = 'is-active',
        $body = $('body');

    /**
     * Initialize Equalizer
     *
     * @author Wesley Cheung <wesley@click.nl>
     */
    self.init = function(){
        $menu_button.on('click', function(){
            $(this).toggleClass(is_active);
            $menu.toggleClass(is_active);

            self.touchDisabler();
        });

        $menu_anchor.on('click', function(){
            $menu_button.toggleClass(is_active);
            $menu.toggleClass(is_active);

            self.touchDisabler();
        });
    };

    /**
     * Touch Disabler
     * Check if menu has is-active class. if it does. Disable it. Otherwise touchmove can be turned on again.
     *
     * @author Wesley Cheung <wesley@click.nl>
     */
    self.touchDisabler = function(){
        if ($menu.hasClass(is_active)){
            $body.on('touchmove', false);
        } else {
            $body.off('touchmove', false);
        }
    };
};

/**
 * Reveal box Youtube IFrame
 * Show iframe only when clicked
 *
 * @author Wesley Cheung <wesley@click.nl>
 */
var reveal_video = function(){
    var self = this;

    self.init = function(){
        var $js_reveal_video = $('.js-reveal-video');

        $js_reveal_video.off('click');

        $js_reveal_video.on('click', function(){
            var reveal_id = $(this).attr('data-open'),
                video_url = $(this).attr('data-video-url'),
                $reveal = $('#'+reveal_id),
                js_iframe_container = '.js-iframe-container';
            $reveal.find(js_iframe_container).html('<iframe class="iframe-responsive" width="100%" height="600" src="'+video_url+'" frameborder="0" allowfullscreen></iframe>');
        });
    };
};

/**
 * Team switch (About)
 * Switching tabs
 * @author Wesley Cheung <wesley@click.nl>
 */
var team_switch = function(){
    var self = this;
    var $target = $('.js-team-btn');

    self.init = function(){
        $target.on('click', function (e){
            e.preventDefault();

            var selected_box = $(this).attr('data-tab');

            // If the object has the class 'is-hidden'
            if ($('.' + selected_box).hasClass('is-hidden')){
                // Loop through $target
                $.each($target, function (key, index){
                    var box = $(index).attr('data-tab');

                    // If the box is not equal to selected box
                    if (box != selected_box){
                        $(index).addClass('secondary');
                        $('.' + box).addClass('is-hidden');
                    } else {
                        $(index).removeClass('secondary');
                        $('.' + selected_box).removeClass('is-hidden');
                    }
                });
            } 
        });
    };
};

/**
 * Radio button switcher to specified input field
 *
 * @author Wesley Cheung <wesley@click.nl>
 */
var radio_switch = function(){
    var self = this;
    var $target = $('.js-radio-switch');

    /**
     * Initialize
     * @author Wesley Cheung <wesley@click.nl>
     */
    self.init = function(){
        // When clicked on $target
        $target.on('click', function(){
            var $this = $(this);
            var $group_field = $('.js-switch-input[data-group="'+$this.attr('data-group')+'"]');

            // Loop through $group_field
            $.each($group_field, function(index, value){

                // If the data-name value equals the current value
                if($(value).attr('data-name') == $this.val()){
                    $(value).removeClass('hide');

                    // If Select2 is present, initialize it
                    if( $(value).has('.js-select2-basic').length ){
                        checkInitSelect2();
                    }
                }else{
                    // Hide the box
                    $(value).addClass('hide');

                    // Empty the input if found
                    if($(value).find('input')){
                        $(value).find('input').val('');
                    }

                    // Empty the select box if found
                    if($(value).find('select')){
                        var $select_box = $(value).find('select');
                        $select_box.prop('selectedIndex', 0);
                    }
                }
            });
        });
    };
};

/**
 * Vacancy Category filter (Homepage)
 * @author Rick van der Burg <rick@click.nl>
 */
var vacancy_category_filter = function( vacancy_page, category ){
    var self = this;
    var $overview = $('.js-vacancy-category-overview');
    var cat_prefix = '.js-category-';
    var $category = $('.js-category');
    var $filter = $('.js-filter');
    var $anchor = $('.js-category-link'); 
    var $vacancy_overview = $('.js-vacancy-overview');
    var params = ''
    var selected = [];
    var api_url = '';

    var vacancy_page = vacancy_page || false;
    var category = category || null;

    self.init = function(){
        // On click vacancy category
        self.onClickVacancyCategory();

        // Get and set all active towns from the session
        api_url = '/api/get-active-towns';
        self.sendAjaxRequest(self.initActiveTowns);

        // When clicking on filter label
        $filter.on('click', function (e){
            e.preventDefault();
            // Get town id
            var town = $(this).attr('data-town');

            // Check if town is all, if so, reset
            if (town === 'all'){
                self.resetCategories();
            } else {
                self.selectCategory(town);
            }
        
            // Update the vacancies or light up boxes
            if( vacancy_page ){
                api_url = '/api/get-vacancy-by-town?category=' + category;
                self.sendAjaxRequest(self.removeVacanciesBasedOnTown);
            } else {
                api_url = '/api/get-vacancy-category-by-town';
                self.sendAjaxRequest(self.lightUpBoxes);
            }
        });
    };

    /**
     * Add the is-active class to all active towns, for initializing
     *
     * @author Dennis Bruinen
     */
    self.initActiveTowns = function( data ){
        // Add the is-active class for every active town
        if( data.towns.length > 0 ){
            $.each(data.towns, function (key, index){
                $('.js-town-' + index).addClass('is-active');
                selected.push(index);
            });
        }else{
            $('.js-town-all').addClass('is-active');
        }
        self.setActiveToSelected();
        
        // Update the vacancies or light up boxes
        if( vacancy_page ){
            api_url = '/api/get-vacancy-by-town?category=' + category;
            self.sendAjaxRequest(self.removeVacanciesBasedOnTown);
        } else {
            api_url = '/api/get-vacancy-category-by-town';
            self.sendAjaxRequest(self.lightUpBoxes);
        }
    };

    /**
     * Gets parameters in the url
     *
     * @credit http://stackoverflow.com/questions/19491336/get-url-parameter-jquery-or-how-to-get-query-string-values-in-js
     * @param sParam
     * @author Rick van der Burg <rick@click.nl>
     * @returns {boolean}
     */
    self.getUrlParameter = function( sParam ){
        var sPageURL = decodeURIComponent(window.location.search.substring(1)),
            sURLVariables = sPageURL.split('&'),
            sParameterName,
            i;

        for (i = 0; i < sURLVariables.length; i++){
            sParameterName = sURLVariables[i].split('=');

            if (sParameterName[0] === sParam){
                return sParameterName[1] === undefined ? true : sParameterName[1];
            }
        }
    };

    /**
     * Let the categories light up based on towns
     * @author Rick van der Burg <rick@click.nl>
     */
    self.sendAjaxRequest = function( callback ){
        $.ajax({
            url: api_url,
            data: {towns: selected},
            success: callback
        });
    };

    /**
     * Removes certain vacancies based on the town
     * @author Rick van der Burg <rick@click.nl>
     */
    self.removeVacanciesBasedOnTown = function( data ){
        if(data['status'] === 'OK'){
            $vacancy_overview.html(data['html']);
            var count_blocks = $vacancy_overview.find('.js-grid-block').length;
            var $vacancy_alert = $('.js-vacancy-alert');
            // When there are no vacancies, don't do anything with equalizer.
            if(count_blocks === 0){
                $vacancy_alert.removeClass('hide');
            }else{
                $vacancy_alert.addClass('hide');

                if (count_blocks > 1){
                    var equalizer_func = new Foundation.Equalizer($vacancy_overview);
                    equalizer_func.applyHeight();
                    // When screen width and height changes
                    $(window).on('resize', function(){
                        var resize_count_blocks = $vacancy_overview.find('.js-grid-block').length;

                        if (resize_count_blocks > 1){
                            equalizer_func.applyHeight();
                        }
                    });
                }
            }
        }
    };

    /**
     * Makes a box darker when it's not active
     * @author Rick van der Burg <rick@click.nl>
     * @param data
     */
    self.lightUpBoxes = function (data){
        $category.removeClass('is-not-active');
        $.each(data, function (key, index){
            if (index === 0){
                $(cat_prefix + key).addClass('is-not-active');
            } else {

            }
        });
    };

    /**
     * Reset the categories and add is-active class to 'Alle'
     * @author Rick van der Burg <rick@click.nl>
     */
    self.resetCategories = function(){
        selected = [];
        $filter.removeClass('is-active');
        $('.js-town-all').addClass('is-active');
    };

    /**
     * Add/Remove a new category to selected array
     * @param select_town
     * @author Rick van der Burg <rick@click.nl> & Dennis Bruinen <dennis@click.nl>
     */
    self.selectCategory = function (select_town){
        var index = selected.indexOf(select_town);
        // If town is already in the array
        if (index > -1){
            // Remove it from the array
            selected.splice(index, 1);
        } else {
            // Add it to the array
            selected.push(select_town);
        }

        // Do we got more than 1 item and is one of them 'all'? Remove''all' from array and remove class 'is-active'
        var find_index_all = selected.indexOf('all');
        if( selected.length > 1 && find_index_all > -1 ){
            selected.splice( find_index_all, 1 );
            $('.js-town-all').removeClass('is-active');
        }

        // Loop through selected towns and add 'is-active'
        this.setActiveToSelected();
    };

    /**
     * Loops through selected towns and adds class 'is-active' to the one which are pressed
     * Or resets them when empty
     * @author Rick van der Burg <rick@click.nl>
     */
    self.setActiveToSelected = function(){
        $filter.removeClass('is-active');
        // When select is filled
        if (selected.length !== 0){
            // Loop through every selected town
            $.each(selected, function (key, index){
                $('.js-town-' + index).addClass('is-active');
            });
            // Update the querystring parameters
            self.changeGetParameters();
        } else {
            // Reset when selected is emty (The Button 'All' turns on)
            self.resetCategories();
        }
    };

    /**
     * Set parameters so they can be added on the url
     * @author Rick van der Burg <rick@click.nl>
     */
    self.changeGetParameters = function(){
        params = '?towns='
        $.each(selected, function (key, index){
            params += index;
            if ((selected.length) - 1 !== key){
                params += ','
            }
        });
    };

    /**
     * On click: check if category has any vacancies, if not: alert, else redirect with chosen towns
     * @author Rick van der Burg <rick@click.nl>
     */
    self.onClickVacancyCategory = function(){
        $anchor.on('click', function (e){
            e.preventDefault();

            var slug = $(this).attr('data-category-slug');
            var category = $('.js-category-'+ slug)[0];
            var url = $(this).attr('href');
            if (!$(category).hasClass('is-not-active')){
                window.location.href = url + params;
            } else {
                var $js_category_alert = $('.js-category-alert');
            }
        });
    };
};

/**
 * Show more news when clicked on 'bekijk meer' button
 * @author Rick van der Burg <rick@click.nl>
 */
var show_more_news = function(){
    var self = this;
    var $show_more_button = $('.js-show-more-news');
    var $show_less_button = $('.js-show-less-news');
    var $news_item = $('.js-news-item');
    var $news_overview = $('.js-news');
    var api_url = 'api/load-more-news';

    self.init = function(){
        // when clicked on show more button
        $show_more_button.on('click', function (e){
            e.preventDefault();
            $show_more_button.attr('disabled', 'disabled');
            // count current amount of news items
            var count = $('.js-news-item').length;
            $.ajax({
                url: api_url,
                data: {count: count},
                success: self.handleMoreNews
            });
        });

        // remove items
        $show_less_button.on('click', function (e){
            e.preventDefault();
            $news_item.each(function (key, index){
                if (key > 5){
                    $(this).remove();
                    $show_less_button.addClass('hide');
                    $show_more_button.removeClass('hide');
                }
            });
        });
    };

    /**
     * Handle ajax success request
     * @param data
     */
    self.handleMoreNews = function (data){
        $show_more_button.removeAttr('disabled');
        $news_overview.append(data.news_items);
        // when limit of news items has been reached
        if (!data.load_more_btn){
            $show_more_button.addClass('hide');
            // $show_less_button.removeClass('hide');
        }

        // add interchange to news items.
        $news_overview.find('.js-interchange').each(function(){
            new Foundation.Interchange($(this));
        });

        var elem = new Foundation.Equalizer($news_overview);
        elem.applyHeight();
    };
};

/**
 * Shore more testimonials when clicked on 'bekijk meer' button
 * @author Rick van der Burg <rick@click.nl>, Wesley Cheung <wesley@click.nl>
 */
var show_more_testimonials = function(){
    var self = this;
    var $show_more_button = $('.js-show-more-testimonials');
    var $show_less_button = $('.js-show-less-testimonials');
    var $testimonial_items = $('.js-testimonial-item');
    var $testimonial_overview = $('.js-testimonials');
    var api_url = 'api/load-more-testimonials';

    self.init = function(){
        // When clicked on show more button
        $show_more_button.on('click', function (e){
            e.preventDefault();
            var count = $('.js-testimonial-item').length;
            $show_more_button.attr('disabled', 'disabled');
            $.ajax({
                url: api_url,
                data: {count: count},
                success: self.handleMoreTestimonials
            });
        });

        // Remove testimonial items after clicking the show less button
        $show_less_button.on('click', function (e){
            e.preventDefault();
            $('.js-testimonial-item').each(function (key, index){
                if (key > 5){
                    $(this).remove();
                }
            });
            $show_more_button.removeClass('hide');
            $show_less_button.addClass('hide');
        });
    };

    /**
     * Load more testimonials based on click on button
     * @param data
     */
    self.handleMoreTestimonials = function (data){
        $show_more_button.removeAttr('disabled');
        $testimonial_overview.append(data.testimonial_items);

        // when limit of testimonial items has been reached
        if (!data.load_more_btn){
            $show_more_button.addClass('hide');
            // $show_less_button.removeClass('hide');
        }

        $testimonial_overview.find('.js-testimonial-item').each(function(){
            new Foundation.Interchange($(this).find('.js-interchange'));

            var $js_reveal_video = $(this).find('.js-reveal-video');
            var reveal_id = $js_reveal_video.attr('data-open');

            // check if data open was found
            if(reveal_id){
                var $reveal_overlay_modal = $('body .reveal-overlay').find('#' + reveal_id);

                // check if there is already a reveal modal existing.
                if($reveal_overlay_modal.length == 0){
                    new Foundation.Reveal($('#' + reveal_id));

                    // Refresh event listeners
                    var reveal_video_func = new reveal_video();
                    reveal_video_func.init();
                }
            }
        });

        var elem = new Foundation.Equalizer($testimonial_overview);
        elem.applyHeight();
    };
};

/**
 * Show recaptcha when typing
 * @author Rianne Oosthoek <rianne@click.nl>
 */
var show_recaptcha = function(){
    var self = this;
    var $field = $('.js-recaptcha-trigger');

    self.init = function(){
        $field.on('keydown', function(){
            // Retrieve parent form from $field
            var $parent_form = $(this).closest('form'); 
            // Get recaptcha children of $parent_form so we can determine which divs should be made visible
            var $recaptcha = $parent_form.find('.js-recaptcha');
            // Show hidden divs
            $recaptcha.each(function(){
                $(this).removeClass('hide');
            });
        });
    };
};

/**
 * Vacancy form
 */
var vacancy_response = function(){
    var self = this;
    var $form       = $('.js-vacancy-response-form');
    var $submit_btn = $('.js-vacancy-response-submit');
    var api_url     = '/api/submit-vacancy-form';
    var $error      = $('.js-vacancy-response-error');
    var $success    = $('.js-vacancy-response-success');

    self.init = function(){
        // Submit form
        $form.on('submit', function(e){
            e.preventDefault();
            var form_data = $form.serializeArray();

            $.ajax({
                url: api_url,
                type: 'POST',
                data: form_data,
                success: self.handleResponseOnForm,
                error: self.handleErrorResponseOnForm
            });
        });
    };

    /**
     * Handle success after ajax request
     * @param data
     */
    self.handleResponseOnForm = function(data){
        $error.addClass('hide');
        $success.addClass('hide');
        if(data.error){
            $error.removeClass('hide');
            $error.html(data.error);
        } else {
            $success.removeClass('hide');
            $success.html(data.success);
            $form.find('input[type=text], textarea').val('');
            // Hide the whole form
            $form.hide();
        }
    };

    /**
     * Handle error after ajax request
     * @param data
     */
    self.handleErrorResponseOnForm = function(data){
        $error.removeClass('hide');
        $error.html('');
        if(data.status == 422){
            $.each(data.responseJSON, function(key, index){
                $.each(index, function(key, error){
                   $error.append(error + '<br>');
                });
            });
        } else {
            $error.html(data.responseText);
        }
    };
};

/**
 * Login in form for volunteers
 */
var login_form = function(){
    var self = this;
    var $form = $('.js-login');
    var api_url = '/api/login';
    var $error = $('.js-login-error');
    var $success = $('.js-login-success');

    self.init = function(){
        $form.on('submit', function(e){
            e.preventDefault();
            var form_data = $form.serializeArray();
            self.performAttempt(form_data);
        });
    };

    /**
     * Perform attempt with ajax call
     * @param form_data
     */
    self.performAttempt = function(form_data){
        $.ajax({
            url: api_url,
            type: 'POST',
            data: form_data,
            success: self.handleResponseOnForm,
            error: self.handleErrorResponseOnForm
        });
    };

    /**
     * Redirect to declaration page when successfull.
     * @param data
     */
    self.handleResponseOnForm = function(data){
        if(data.success){
            $error.addClass('hide');
            $success.removeClass('hide');
            $success.html(data.success);

            window.location.href = '/account';
        }
    };

    /**
     * Send error to visitor when data invalid
     * @param data
     */
    self.handleErrorResponseOnForm = function(data){
        $error.removeClass('hide');
        $error.html('');
        if(data.status == 422){
            $.each(data.responseJSON, function(key, index){
                $.each(index, function(key, error){
                    $error.append(error + '<br>');
                });
            });
        } else {
            $error.html(data.responseText);
        }
    };
};

/**
 * Numbers only field
 *
 * @author Wesley Cheung <wesley@click.nl>
 */
var numbers_only = function(){
    var self = this;

    self.init = function(){
        var $js_numbers_only = $('.js-numbers-only');

        $js_numbers_only.focus(function(){
            $(this).keydown(function(e){
                var code = e.which || e.keyCode;

                if(code >= 48 && code <= 57 || code >= 96 && code <= 105 || code == 8 || code == 9){
                    return true;
                }else{
                    return false;
                }
            });
        });
    };
};

/**
 * Functionality for checking a field for only for number values
 *
 * @author Wesley Cheung <wesley@click.nl>
 */
var money_format = function(){
    var self = this;

    self.init = function(){
        var $js_money_format = $('.js-money-format');
        var check_keycode;
        var current_value;
        var value;

        // Check on focus, so even if the user uses keyboard to navigate it will work.
        $js_money_format.focus(function(){
            // On key down check if it matches one of the keycodes.
            $(this).keydown(function(e){
                check_keycode = self.checkKeycode(e);

                // Check if keycode is not equal. Disable the key input.
                if(!check_keycode){
                    return false;
                }
            });
        });

        // Field is not focused anymore.
        $js_money_format.blur(function(){
            current_value = $(this).val();
            current_value = self.moneyFormat(current_value, /\,/g, /\./g, '.');

            value = current_value;

            // Show the new value.
            if(!isNaN(value)){
                $(this).val(value);
            }
        });
    };

    /**
     * Check on the user input.
     *
     * @author Wesley Cheung <wesley@click.nl>
     * @param {event} e
     * @returns {boolean}
     * @info (to check on the keycode) - http://keycode.info/
     */
    self.checkKeycode = function(e){
        var code = e.which || e.keyCode;
        // 46           : delete
        // 37 t/m 40    : arrows
        // 48 t/m 57    : 0-9
        // 96 t/m 105   : num-pad (0-9)
        // 8            : backspace
        // 9            : tab
        // 188          : comma
        // 190          : period
        // 110          : decimal point
        var code_array = [8, 9, 37, 38, 39, 40, 46, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 110, 188, 190];

        // Check if code of pressed key appears in array and if the ctrl, shift or alt key is being pressed
        if( $.inArray( code, code_array) !== -1 && !e.ctrlKey && !e.altKey && !e.shiftKey ){
            return true;
        }else{
            return false;
        }
    };

    /**
     * Formatting the value depending on the one/two regex that's given.
     * It will leave the last of the parameter search intact so you will get a money format.
     * For example : 10.000.000.00 will turn into 10000000.00
     *
     * @author Wesley Cheung <wesley@click.nl>
     * @param {string}  string
     * @param {regex}   regex (possible to stay empty by using null)
     * @param {regex}   regex2
     * @param {string}  search
     * @returns {float}
     * @info (for more information about what the regex does) - https://regex101.com/r/EsmB10/1
     */
    self.moneyFormat = function(string, regex, regex2, search){
        // Check for all the commas and replace to dot.
        if(regex != null){ 
            string = string.replace(regex, search); 
        }

        var formatted = '';

        // Find all the dots. -1 will keep the last dot alive.
        var all_occurrences = (string.match(regex2) || []).length - 1;

        if(all_occurrences == -1){
            formatted = string;
        }else{
            // Find the last dot in the index minus the the total of dots
            var last_index = string.lastIndexOf(search) - all_occurrences;

            // Replace all the dots into empty
            string = string.replace(regex2, '');

            // Put the dot back with the last_index
            var formatted = string.slice(0, last_index) + search + string.slice(last_index);
        }

        // Send the new format back with maximum of two numbers after the dot.
        return parseFloat(formatted).toFixed(2);
    };
};

/**
 * Functionality for checking a field for only for number values
 *
 * @author Wesley Cheung <wesley@click.nl>
 */
var number_format = function(){
    var self = this;

    self.init = function() {
        var $js_number_format = $('.js-number-format');
        var check_keycode;

        // Check on focus, so even if the user uses keyboard to navigate it will work.
        $js_number_format.focus(function() {
            // On key down check if it matches one of the keycodes.
            $(this).keydown(function(e){
                check_keycode = self.checkKeycode(e);

                // Check if keycode is not equal. Disable the key input.
                if(!check_keycode){
                    return false;
                }
            });
        });
    };

    /**
     * Check on the user input.
     *
     * @author Wesley Cheung <wesley@click.nl>
     * @param {event} e
     * @returns {boolean}
     * @info (to check on the keycode) - http://keycode.info/
     */
    self.checkKeycode = function(e) {
        var code = e.which || e.keyCode;
        // 48 t/m 57    : 0-9
        // 96 t/m 105   : num-pad (0-9)
        // 8            : backspace/delete
        // 9            : tab
        // 190          : period
        // 110          : decimal point

        if(code >= 48 && code <= 57 ||
           code >= 96 && code <= 105 ||
           code == 8    ||
           code == 190  ||
           code == 110  ||
           code == 9) {
            return true;
        }else{
            return false;
        }
    };
};

/**
 * Functionality for declarations page for volunteers
 *
 * @author Dennis Bruinen <dennis@click.nl>
 */
var declarations = function(){
    var self = this;

    self.init = function(){
        var $declaration_month = $('.js-declaration-month-button');

        // When a month is clicked, show the correct month and hide the rest
        $declaration_month.click(function(e){
            e.preventDefault();

            var key = $(this).attr('data-declaration-month');
            $('.js-declaration-month-table').slideUp(500);
            $('.js-declaration-month-table[data-declaration-month='+key+']').slideDown(500);
        });

        // Listener for clicking on elements with the attribute data-confirm
        $('body').on('click', '.js-btn-confirm-delete', function(e){
            e.preventDefault();

            var id = $(this).attr('data-id');

            var $modal = $('.js-confirm-delete');

            // Define html for modal
            var html = '<div class="confirm-wrapper">'
            html += '<p>' + Lang.get('frontend.js.delete_declaration_are_you_sure') + '</p>';
            html += '<div class="button-group small">';
            html += '<button class="button secondary" data-close aria-label="' + Lang.get('frontend.js.cancel') + '" type="button">' + Lang.get('frontend.js.cancel') + '</button>';
            html += '<button class="button success" data-delete-record="' + id + '" aria-label="' + Lang.get('frontend.js.confirm') + '" type="button">' + Lang.get('frontend.js.confirm') + '</button>';
            html += '</div></div>';

            // Add html to modal
            $modal.html( html );
            // Open popup
            var popup = new Foundation.Reveal($modal);
            popup.open();
        });

        // Listener for clicking on final delete button in confirm modal
        $('body').on('click', '[data-delete-record]', function(){
            var id = $(this).attr('data-delete-record');
            // Submit the delete form
            $('#delete_form_' + id).submit();
        });
    };
};