/*
 * Script which shows/ closes the cookie consent popup and creates the accepted cookies
 * 
 * @author Joost Ligthart <joost@click.nl>
 * @edited by Lennart van Welzen <lennart@click.nl> and Rianne Oosthoek <rianne@click.nl>
 */
window.cookie_consent_popup = function() {
    var self = this;
    var $button_accept_all              = document.querySelector('.js-button-accept-all');
    var $button_accept_prefered         = document.querySelector('.js-button-accept-prefered');
    var $radio_buttons                  = document.querySelectorAll('.js-cookie-consent-radio-buttons');
    var $cookie_window                  = document.querySelector('.js-cookie');
    var $open_cookie_window_button      = document.querySelectorAll('.js-open-cookie-popup');
    var $body                           = document.body;

    var is_social_media_accepted       = null;
    var is_marketing_accepted          = null;
    var is_cookies_set                 = false;

    self.init = function() {
        // Necessary for cookie settings page
        if($cookie_window !== null) {
            // Show cookie banner on page load when cookie are not set yet
            if( self.getCookie('social_media_cookie') === '' && self.getCookie('marketing_cookie') === '' ) {
                $cookie_window.classList.remove('hide');
                $body.classList.add('disable-scroll');
            } else {
                // Iterate through all cookie window triggers and add listener
                for(var i = 0; i < $open_cookie_window_button.length; i++){
                    if($open_cookie_window_button[i] !== undefined){
                        $open_cookie_window_button[i].addEventListener('click', function(e) {
                            $cookie_window.classList.remove('hide');
                            $body.classList.add('disable-scroll');
                        });
                    }
                }
            }
        }

        // Set the is_cookies_set to true when both cookies are set. This will be used in future checks
        if( self.getCookie('social_media_cookie') !== '' && self.getCookie('marketing_cookie') !== '' ) {
            is_cookies_set = true;
        }

        // Set the radio buttons based on the cookie values
        if(is_cookies_set) {
            self.setRadioButtons();
            // Show preferences button
            $button_accept_all.classList.add('hide');
            $button_accept_prefered.classList.remove('hide');
        }

        // Iterate through radio buttons and set listener for when user clicks on cookie options
        for(var i = 0; i < $radio_buttons.length; i++){
            if($radio_buttons[i] !== undefined){
                $radio_buttons[i].addEventListener('click', self.changeCookieSaveButton);
            }
        }

        // When the user accepts all cookies, create the cookie
        $button_accept_all.addEventListener('click', self.acceptAllCookies);

        // When the user accepts all cookies, create the cookie
        $button_accept_prefered.addEventListener('click', self.acceptPreferredCookies);
    };

    /**
     * This code will only create the cookies accepted by the user
     *
     * @author Joost Ligthart <joost@click.nl>
     * @edited by Rianne Oosthoek <rianne@click.nl>
     * @param  event
     * @return void
     */
    self.acceptPreferredCookies = function(event) {
        event.preventDefault();
        // Create cookie based on the boolean values
        // Value of cookie must be typeof string because boolean `false` results into an empty value
        self.setCookie('social_media_cookie', (is_social_media_accepted ? '1' : '0'), 365);
        self.setCookie('marketing_cookie', (is_marketing_accepted ? '1' : '0'), 365);
        // Hide the cookie consent message if it's in a popup
        if($cookie_window !== null) {
            $cookie_window.classList.add('hide');
        }
    };

    /**
     * This code will create all cookies
     *
     * @author Joost Ligthart <joost@click.nl>
     * @edited by Rianne Oosthoek <rianne@click.nl>
     * @param  event
     * @return void
     */
    self.acceptAllCookies = function(event) {
        event.preventDefault();
        // Create both cookies for social media and marketing
        // Value of cookie must be typeof string because boolean `false` results into an empty value
        self.setCookie('social_media_cookie', '1', 365);
        self.setCookie('marketing_cookie', '1', 365);
        // Hide the cookie consent message if it's in a popup
        if($cookie_window !== null) {
            $cookie_window.classList.add('hide');
        }
    };

    /**
     * This code set the booleans based on the clicked radio button and show the right save button
     *
     * @author Joost Ligthart <joost@click.nl>
     * @param  event
     * @return void
     */
    self.changeCookieSaveButton = function(event) {
        if(['social_media_consent', 'marketing_consent'].includes(event.target.name)){
            // Check the target name, so we set the right boolean value
            if(event.target.name == 'social_media_consent'){
                is_social_media_accepted = parseInt(event.target.value);
            } else {
                is_marketing_accepted = parseInt(event.target.value);
            }
        }

        // if the cookies not yet set and both booleans are not null (has changed)
        if( !is_cookies_set && ( is_social_media_accepted !== null && is_marketing_accepted !== null ) ||
            // if the cookies are set and either boolean is not null (has changed)
            is_cookies_set && ( is_social_media_accepted !== null || is_marketing_accepted !== null) ){
            $button_accept_all.classList.add('hide');
            $button_accept_prefered.classList.remove('hide');
        }
    };

    /**
     * Set cookie in client browser
     *
     * @author Rianne Oosthoek <rianne@click.nl>
     * @edited by Joost Ligthart <joost@click.nl>
     * @param   {string}    name  Name of the cookie
     * @param   {boolean}   value  Value of the cookie
     * @param   {int}       days  Amount of days wherein the cookie expires
     */
    self.setCookie = function setCookie( name, value, days ) {
        var expires = '';
        // If days are given, set expire date
        if( days ){
            // Create date instance
            var date = new Date();
            // Turn days into milliseconds 
            date.setTime( date.getTime() + ( days*24*60*60*1000 ) );
            // Create expire date
            expires = '; expires=' + date.toUTCString();
        }
        // Set cookie
        document.cookie = name + '=' + ( value || '' )  + expires + '; path=/';
        // Reload the page so the PHP checks will work in the blade files
        location.reload(); 
    };

    /**
     * Get cookie name
     *
     * @author Rianne Oosthoek <rianne@click.nl>
     * @edited by Joost Ligthart <joost@click.nl>
     * @param   {string}  cookie_name   Name of the cookie we want to check for existence
     * @return  {string}  cookie value or empty string
     */
    self.getCookie = function( cookie_name ){
        var name = cookie_name + '=';
        var cookie_arr = document.cookie.split(';');

        // Loop through all cookies
        for( var i = 0; i < cookie_arr.length; i++ ) {
            var cookie = cookie_arr[i];
            // When first character is a space, get the substring after the space
            while( cookie.charAt(0) == ' ' ){
                cookie = cookie.substring(1);
            }
            // Check if cookie name exists in array of cookies
            if( cookie.indexOf(name) === 0 ){
                return cookie.substring( name.length, cookie.length );
            } 
        }

        // Return empty string when cookie doesn't exists
        return '';
    };

    /**
     * Set the radio buttons based on the cookie values
     *
     * @author Joost Ligthart <joost@click.nl>
     * @edited by Rianne Oosthoek <rianne@click.nl>
     * @return void
     */
    self.setRadioButtons = function(){
        // Set the right social media radio button and boolean based on the cookie value
        if( self.getCookie('social_media_cookie') !== '' && self.getCookie('social_media_cookie') === '1' ){
            document.getElementsByClassName('js-consent-social-media-accept')[0].checked = true;
            is_social_media_accepted = true;
        } else {
            document.getElementsByClassName('js-consent-social-media-deny')[0].checked = true;
            is_social_media_accepted = false;
        }
        // Set the right markting radio button and boolean based on the cookie value
        if( self.getCookie('marketing_cookie') !== '' && self.getCookie('marketing_cookie') ==='1' ){
            document.getElementsByClassName('js-consent-marketing-accept')[0].checked = true;
            is_marketing_accepted = true;
        } else {
            document.getElementsByClassName('js-consent-marketing-deny')[0].checked = true;
            is_marketing_accepted = false;
        }
    };
};
