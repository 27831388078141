/**
 * Order modal for orders
 *
 * @author Hasan Özaynaci <hasan@click.nl>
 */
window.order_modal = function()
{
    var self = this;
    var $order_form = $('#js-order-form');
    var $confirmation_message = $('.js-modal-confirmation');
    var $order_message = $('.js-modal-message');

    var product_slug = null;
    var csrf_token = null;

    /**
     * Order modal for orders
     *
     * @author Hasan Özaynaci <hasan@click.nl>
     * @param slug & csrf
     */
    
    self.init = function(slug, csrf)
    {
        product_slug = slug;
        csrf_token = csrf;
        $order_form.submit(self.submitForm);
    };

    /**
     * API call for form
     *
     * @author Hasan Özaynaci <hasan@click.nl>
     * @param event
     */
    self.submitForm = function(e)
    {
        // Prevent reloading the page so content of modal window can be changed
        e.preventDefault();

        $.ajax({
            type: 'POST',
            url: '/api/submit-order-form/' + product_slug,
            dataType: 'json',
            data: {_token: csrf_token}
        }).done(function(data) {
            // Change content of modal window
            $confirmation_message.addClass('hide');
            $order_message.removeClass('hide');
        });
    };
};